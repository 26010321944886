/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import {
  TrackingDataV2 as TfsTrackingData,
  TrackingServiceV2,
} from '@oneaudi/audi-tracking-service';
import { FeatureServicesBindingAdditions } from '@volkswagen-onehub/oneaudi-os-adapter-tracking';
import {
  DataLayerEventAttributes,
  DataLayerEventInfo,
} from '@volkswagen-onehub/oneaudi-os-onesight';
import { Logger } from '@feature-hub/core';
import { addMissingTfsEventAttributes } from './Events';
import { TrackingHelper } from './TrackingHelper';

declare global {
  interface Window {
    // eslint-disable-next-line
    digitalData: any;
    // eslint-disable-next-line
    registeredServices: any;
    sendExitLinkEventCounter: number;
    previousSlickSlideIndex: number | null;
    currentSlickSlideIndex: number | null;
    onInitCallerCount: number;
  }
}
export class TrackingV2Helper implements TrackingHelper {
  private trackingService: TrackingServiceV2 & FeatureServicesBindingAdditions;
  // eslint-disable-next-line
  selectedComponent: any;

  private loggerService: Logger;

  constructor(
    trackingService: TrackingServiceV2 & FeatureServicesBindingAdditions,
    loggerService: Logger,
  ) {
    this.selectedComponent = null;
    this.trackingService = trackingService;
    this.loggerService = loggerService;

    if (typeof window !== 'undefined') {
      // eslint-disable-next-line
      window.onload = (_event) => {
        this.init();
      };
    }
  }

  init(): void {
    let initialStart = 0;
    let initialEnd = 0;
    // Commented out to check later if SSR will be affected
    // // eslint-disable-next-line
    // let container: any;
    // if (typeof document !== 'undefined') {
    //   // eslint-disable-next-line
    //   container = document.querySelector(`#${__FEATURE_APP_NAME__}`);
    // }
    const featureApp = document.querySelector(
      `[data-test-id="${this.trackingService.featureAppId}"]`,
    );
    const sliderClasses = (featureApp || document).querySelectorAll('.slick-slide');
    // eslint-disable-next-line
    sliderClasses?.forEach((sliderClass) => {
      // eslint-disable-next-line
      sliderClass.addEventListener('keydown', (event: any) => {
        if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
          this.trackEventClickService(null, event);
        }
      });
      // eslint-disable-next-line
      sliderClass.addEventListener(
        'touchstart',
        // eslint-disable-next-line
        (e: any) => {
          // eslint-disable-next-line
          initialStart = e?.changedTouches[0].screenX;
        },
        false,
      );
      // eslint-disable-next-line
      sliderClass.addEventListener(
        'touchend',
        // eslint-disable-next-line
        (e: any) => {
          initialEnd = e?.changedTouches[0].screenX;
          // eslint-disable-next-line
          if (Math.abs(initialStart - initialEnd) >= screen.width * 0.1953125) {
            this.selectedComponent = 'image';
            this.trackEventClickService(null, null, true);
          }
        },
        false,
      );
      // eslint-disable-next-line
      sliderClass.addEventListener(
        'mousedown',
        // eslint-disable-next-line
        (e: any) => {
          initialStart = e.screenX;
        },
        false,
      );
      // eslint-disable-next-line
      sliderClass.addEventListener(
        'mouseup',
        // eslint-disable-next-line
        (e: any) => {
          initialEnd = e.screenX;
          // eslint-disable-next-line
          if (Math.abs(initialStart - initialEnd) >= screen.width * 0.1953125) {
            this.selectedComponent = 'image';
            this.trackEventClickService(null, null, true);
          }
        },
        false,
      );
    });
  }
  // eslint-disable-next-line
  getDomIndex(target: any) {
    return ([] as string[]).slice.call(target.parentNode.children).indexOf(target);
  }
  // eslint-disable-next-line
  trackEventClickService(component: any, event: any = null, isSwiped = false) {
    // eslint-disable-next-line
    function getDomIndex(target: any) {
      return ([] as string[]).slice.call(target.parentNode.children).indexOf(target);
    }
    // let previousSlide = getDomIndex(document.querySelector('.slick-active'));

    // eslint-disable-next-line
    let previousSlide: any;

    if (typeof document !== 'undefined') {
      const featureApp = document.querySelector(
        `[data-test-id="${this.trackingService.featureAppId}"]`,
      );
      previousSlide = getDomIndex((featureApp || document).querySelector('.slick-active'));
    }

    setTimeout(() => {
      if (!this.selectedComponent && !component) this.selectedComponent = 'image';

      if (this.selectedComponent || component) {
        // eslint-disable-next-line
        component = component || this.selectedComponent;
        // eslint-disable-next-line
        let currentSlide: any;

        if (typeof document !== 'undefined') {
          const featureApp = document.querySelector(
            `[data-test-id="${this.trackingService.featureAppId}"]`,
          );
          currentSlide = getDomIndex((featureApp || document).querySelector('.slick-active'));
        }
        // eslint-disable-next-line
        function element() {
          // eslint-disable-next-line
          if (parseInt(component)) {
            return 'number';
          }
          if (component.includes('small')) {
            return 'arrow';
          }
          if (component.includes('system')) {
            return 'icon';
          }
          return 'image';
        }
        // eslint-disable-next-line
        function attributesValue() {
          if (component.includes('small')) {
            return 'pagination bar';
          }
          if (component.includes('system')) {
            return '';
            // eslint-disable-next-line
          } else if (parseInt(component)) {
            return 'pagination bar';
          } else return 'image bar';
        }
        // eslint-disable-next-line
        function elementClick() {
          if (component.includes('small')) {
            return 'change image';
          }
          if (isSwiped) {
            return 'change image';
            // eslint-disable-next-line
          } else if (parseInt(component)) {
            return 'change image';
          } else if (component.includes('system')) {
            return 'click on icon';
          } else if (component === 'image') {
            return 'change image';
          }
          return '';
        }
        // eslint-disable-next-line
        function eventActionClick() {
          if (component.includes('small')) {
            return 'image';
          }
          if (isSwiped) {
            return 'image';
            // eslint-disable-next-line
          } else if (parseInt(component)) {
            return 'image';
          } else if (component.includes('system')) {
            return 'content';
          } else if (component === 'image') {
            return 'image';
          }
          return '';
        }
        const tilda = '~';
        const prevTildaCur = `${previousSlide}${tilda}${currentSlide}`;
        // eslint-disable-next-line
        function currentPrevSlide() {
          if (component.includes('system')) {
            return currentSlide;
          }
          if (isSwiped) {
            return prevTildaCur.trim();
          }
          if (isSwiped) {
            return prevTildaCur.trim();
          }
          if (component.includes('this is video of etron')) {
            return currentSlide;
          }
          return prevTildaCur.trim();
        }
        let actionTypeElement = '';
        if (event?.type) {
          // handle keyboard event here
          if (['37', '39'].includes(String(event.keyCode))) actionTypeElement = 'keyboard';
        } else {
          // handle click event here
          actionTypeElement = 'click';
        }
        if (isSwiped) {
          actionTypeElement = 'swipe';
          // eslint-disable-next-line
          component = 'image swipe';
        }
        const attributes = {
          componentName: 'feature-gallery',
          clickID: '',
          elementName: element(),
          value: attributesValue(),
          label: component,
          targetURL: '',
          actionType: `${actionTypeElement}`,
          pos: `${currentPrevSlide()}`,
        };
        const payload: TfsTrackingData = this.generateEvent(
          // eslint-disable-next-line
          // @ts-ignore
          attributes,
          `${eventActionClick()}`,
          `feature gallery - ${elementClick()}`,
        );
        this.trackingService.track(payload);
        // eslint-disable-next-line
        // @ts-ignore
        // eslint-disable-next-line
        let digitalData: any;
        if (typeof window !== 'undefined') {
          // eslint-disable-next-line
          digitalData = window.digitalData;
        }
        const eventCount = window.digitalData?.event?.length;
        this.loggerService.info(
          `[Legacy Tracking V2 :: sendClickEvent] Datalayer was updated (see 'window.digitalData'), events: ${eventCount}, payload: `,
          payload,
        );
      }
    }, 200);
  }

  getTrackingService(): TrackingServiceV2 & FeatureServicesBindingAdditions {
    return this.trackingService;
  }

  // eslint-disable-next-line
  private generateEvent = (
    attributes: DataLayerEventAttributes,
    eventAction: string,
    eventName: DataLayerEventInfo,
  ): TfsTrackingData => {
    return {
      event: {
        // eslint-disable-next-line
        // @ts-ignore
        attributes: addMissingTfsEventAttributes(attributes),
        eventInfo: {
          eventAction,
          eventName: eventName.eventAction,
        },
      },
    };
  };

  sendReadyEvent = () => {
    const attributes = {
      componentName: 'feature-gallery',
    };
    const payload: TfsTrackingData = this.generateEvent(
      // eslint-disable-next-line
      // @ts-ignore
      attributes,
      'feature_app_ready',
      'feature gallery - feature app ready',
    );

    this.trackingService.track(payload);
    // eslint-disable-next-line
    // @ts-ignore
    // eslint-disable-next-line
    let digitalData: any;
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line
      digitalData = window.digitalData;
    }
    const eventCount = window.digitalData?.event?.length;
    this.loggerService.info(
      `[Legacy Tracking V2 :: sendReadyEvent] Datalayer was updated (see 'window.digitalData'), events: ${eventCount}, payload: `,
      payload,
    );
  };

  sendClickEvent = (component: string) => {
    this.selectedComponent = component;
    this.trackEventClickService(component);
  };

  sendExitLinkEvent = (component: string, link: string) => {
    // eslint-disable-next-line
    function getDomIndex(target: any) {
      return ([] as string[]).slice.call(target.parentNode.children).indexOf(target);
    }
    // let currentSlide = getDomIndex(document.querySelector('.slick-active'));
    // eslint-disable-next-line
    let currentSlide: any;

    if (typeof document !== 'undefined') {
      const featureApp = document.querySelector(
        `[data-test-id="${this.trackingService.featureAppId}"]`,
      );
      currentSlide = getDomIndex((featureApp || document).querySelector('.slick-active'));
    }
    function validateURL() {
      // eslint-disable-next-line
      const isExternalURL = (url: string) => new URL(url).origin !== location.origin;

      if (isExternalURL(link)) {
        return 'exit_link';
      }
      return 'internal_link';
    }
    const attributes = {
      clickID: '',
      componentName: 'feature-gallery',
      elementName: 'text link',
      targetURL: link,
      label: component,
      pos: `${currentSlide}`,
      actionType: 'click',
    };
    const payload: TfsTrackingData = this.generateEvent(
      // eslint-disable-next-line
      // @ts-ignore
      attributes,
      `${validateURL()}`,
      'feature gallery - click on link',
    );

    this.trackingService.track(payload);
    // eslint-disable-next-line
    // @ts-ignore
    // eslint-disable-next-line
    let digitalData: any;
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line
      digitalData = window.digitalData;
    }
    const eventCount = window.digitalData?.event?.length;
    this.loggerService.info(
      `[Legacy Tracking V2 :: sendExitLinkEvent] Datalayer was updated (see 'window.digitalData'), events: ${eventCount}, payload: `,
      payload,
    );
  };
  // eslint-disable-next-line
  trackImpressionEvent = (ref: React.MutableRefObject<any>, updateCallback: Function) => {
    this.trackingService.registerImpressionTracking(ref.current, () => {
      const attributes = {
        componentName: 'feature-gallery',
        elementName: '',
        value: '',
      };
      const payload: TfsTrackingData = this.generateEvent(
        // eslint-disable-next-line
        // @ts-ignore
        attributes,
        'impression',
        'feature gallery - impression',
      );

      setTimeout(() => {
        // eslint-disable-next-line
        // @ts-ignore
        // eslint-disable-next-line
        let digitalData: any;
        if (typeof window !== 'undefined') {
          // eslint-disable-next-line
          digitalData = window.digitalData;
        }
        const eventCount = window.digitalData?.event?.length;
        this.loggerService.info(
          `[Legacy Tracking V2 :: ImpressionEvent] Datalayer was updated (see 'window.digitalData'), events: ${eventCount}, payload: `,
          payload,
        );
        updateCallback();
      }, 1000);

      return payload;
    });
  };
}
