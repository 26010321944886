import styled from 'styled-components';
import { constants } from '../../../constants';

const device = {
  desktop: `(min-width:${constants.displaySizes.desktop})`,
  largeDesktop: `(min-width:${constants.displaySizes.largeDesktop})`,
  smallDesktop: `(min-width:${constants.displaySizes.smallDesktop})`,
  mobile: `(max-width:${constants.displaySizes.mobile})`,
  tablet: `(min-width: ${constants.displaySizes.tablet})`,
  smallTablet: `(max-width: ${constants.displaySizes.tablet})`,
};

interface componentProps {
  theme: string;
  width?: number;
  isSingleSlide?: boolean;
}

interface SliderWrapProps {
  isSingleSlide?: boolean;
}

export const SlickSlider = styled.div`
  position: relative;
  width: 100%;

  .slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .slick-track:before,
  .slick-track:after {
    display: table;

    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }

  /* Arrows */

  .slick-prev,
  .slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    &:hover,
    &:focus {
      outline: none;
      background: transparent;
      color: transparent;
      &:before {
        opacity: $slick-opacity-on-hover;
      }
    }
    &.slick-disabled:before {
      opacity: $slick-opacity-not-active;
    }
    &:before {
      font-family: $slick-font-family;
      font-size: 20px;
      line-height: 1;
      color: $slick-arrow-color;
      opacity: $slick-opacity-default;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  .slick-prev {
    left: -25px;
    [dir='rtl'] & {
      left: auto;
      right: -25px;
    }
    &:before {
      content: $slick-prev-character;
      [dir='rtl'] & {
        content: $slick-next-character;
      }
    }
  }

  .slick-next {
    right: -25px;
    [dir='rtl'] & {
      left: -25px;
      right: auto;
    }
    &:before {
      content: $slick-next-character;
      [dir='rtl'] & {
        content: $slick-prev-character;
      }
    }
  }

  /* Dots */

  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }

  .slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    li {
      position: relative;
      display: inline-block;
      height: 20px;
      width: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;
      button {
        border: 0;
        background: transparent;
        display: block;
        height: 20px;
        width: 20px;
        outline: none;
        line-height: 0px;
        font-size: 0px;
        color: transparent;
        padding: 5px;
        cursor: pointer;
        &:hover,
        &:focus {
          outline: none;
          &:before {
            opacity: $slick-opacity-on-hover;
          }
        }
        &:before {
          position: absolute;
          top: 0;
          left: 0;
          content: $slick-dot-character;
          width: 20px;
          height: 20px;
          font-family: $slick-font-family;
          font-size: $slick-dot-size;
          line-height: 20px;
          text-align: center;
          color: $slick-dot-color;
          opacity: $slick-opacity-not-active;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
      &.slick-active button:before {
        color: $slick-dot-color-active;
        opacity: $slick-opacity-default;
      }
    }
  }
`;

export const CarouselContainer = styled.div<componentProps>`
  background-color: ${(props) =>
    props.theme === constants.theme.darkTheme ? constants.color.black : constants.color.white};
  display: flex;
  flex-flow: column;
  height: 100%;
  margin: 0 auto;
  max-width: 1920px;
  width: 100%;
`;

export const FlexContainer = styled.div<componentProps>`
  display: flex;
`;

export const SliderWrap = styled.div<SliderWrapProps>`
  margin: 0;
  overflow: hidden;
  height: 100%;

  ${({ isSingleSlide }) =>
    isSingleSlide
      ? `
        .slick-cloned {
          height: 0px;
          display: none !important;
          }
      `
      : ''}

  .slick-slide {
    padding: 0px;
  }

  .slick-arrow {
    z-index: 20;
  }
  .slick-prev {
    left: 60px;
  }
  .slick-prev:before {
    content: none;
  }
  .slick-next {
    right: 60px;
  }
  .slick-next:before {
    content: none;
  }
`;

export const TextBlockSlider = styled.div<componentProps>`
  height: auto;

  a {
    color: ${(props) =>
      props.theme === constants.theme.darkTheme ? constants.color.white : constants.color.black};
  }

  ${({ isSingleSlide }) =>
    isSingleSlide
      ? `
      .slick-cloned {
        height: 0px;
        display: none !important;
      }
      .slick-dots {
        overflow: hidden;
        visibility: hidden;
      }
    `
      : ''}

  .slick-slide {
    padding: 0px;
    transition: none !important;

    .fg-text-block-content,
    .fg-wltp-content {
      opacity: 0 !important;
      transform: translateY(30px) !important;
    }
  }

  .slick-slider {
    display: grid;
    grid-template-columns: auto;
    padding-left: 28px;
    padding-right: 28px;

    @media ${device.tablet} {
      grid-template-columns: auto auto;
      column-gap: 17px;
      padding-left: 40px;
      padding-right: 40px;
    }
    @media ${device.smallDesktop} {
      column-gap: 48px;
      padding-left: 60px;
      padding-right: 60px;
    }
    @media ${device.desktop} {
      column-gap: 97px;
      padding-left: 96px;
      padding-right: 96px;
    }
    @media ${device.largeDesktop} {
      column-gap: 60px;
      padding-left: 337px;
      padding-right: 337px;
    }

    .slick-list {
      padding: 0 !important;
      @media ${device.tablet} {
        grid-column: 1 / 3;
        grid-row: 1/2;
      }
    }
  }

  .slick-slider {
    display: grid;
    grid-template-columns: auto;
    padding-left: 28px;
    padding-right: 28px;
    position: unset;

    @media ${device.tablet} {
      column-gap: 17px;
      grid-template-columns: ${(props) => `auto ${props.width}px`};
      padding-left: 40px;
      padding-right: 40px;
      position: relative;
    }
    @media ${device.smallDesktop} {
      column-gap: 48px;
      padding-left: 60px;
      padding-right: 60px;
    }
    @media ${device.desktop} {
      column-gap: 97px;
      padding-left: 96px;
      padding-right: 96px;
    }
    @media ${device.largeDesktop} {
      column-gap: 60px;
      margin: 0 auto;
      max-width: 1920px;
      padding-left: 337px;
      padding-right: 337px;
    }

    .slick-list {
      @media ${device.tablet} {
        grid-column: 1 / 3;
        grid-row: 1/2;
      }
    }
  }

  .slick-arrow {
    display: none;
    position: absolute;
    top: 5%;
    bottom: 0px;
    vertical-align: middle;
    @media ${device.tablet} {
      top: 50%;
    }
    @media ${device.smallDesktop} {
      top: 50%;
    }
    @media ${device.largeDesktop} {
      top: 50%;
    }
  }
  .slick-active {
    transition: none;

    .fg-text-block-content {
      opacity: 1 !important;
      transform: translateY(0) !important;
      transition:
        opacity 1.5s 1s,
        transform 1.5s 1s !important;
      transition-timing-function: cubic-bezier(0.05, 0, 0.25, 1) !important;
    }

    .fg-wltp-content {
      opacity: 1 !important;
      transform: translateY(0) !important;
      transition:
        opacity 1.5s 1.5s,
        transform 1.5s 1.5s !important;
      transition-timing-function: cubic-bezier(0.05, 0, 0.25, 1) !important;
    }
  }
  .slick-prev {
    display: none !important;
  }
  .slick-next {
    display: none !important;
  }
  .slick-next:before,
  .slick-prev:before {
  }
  .slick-dots {
    background-color: ${(props) =>
      props.theme === constants.theme.darkTheme ? constants.color.black : constants.color.white};
    bottom: 0px;
    display: flex !important;
    grid-row: 1/2;
    height: 44px;
    position: unset;
    right: 0;
    width: auto;
    z-index: 0;

    @media ${device.tablet} {
      grid-column: 2/2;
      grid-row: 1/2;
      /* left: -12px; */
      margin: 0;
      position: absolute;
      top: 0;
    }

    .prev {
      margin-right: 0px;

      :focus-visible {
        outline: 1px dotted #212121;
        outline: 5px auto -webkit-focus-ring-color;
      }
    }

    .next {
      :focus-visible {
        outline: 1px dotted #212121;
        outline: 5px auto -webkit-focus-ring-color;
      }
    }

    .goTo {
      padding: 0 14px;
      width: 40px;
      color: ${(props) =>
        props.theme === constants.theme.darkTheme ? constants.color.white : constants.color.black};
      @media ${device.tablet} {
        width: 44px;
        height: 44px;

        :focus-visible {
          outline: 1px dotted #212121;
          outline: 5px auto -webkit-focus-ring-color;
        }
      }

      span {
        font-size: 16px;
        line-height: 1.4;
      }
    }
  }
  .slick-dots ul {
    padding-left: 0px;
  }
  .slick-dots li {
    position: relative;
    width: auto;
    height: 44px;
    text-align: center;
    margin: 0 0rem;

    button {
      width: 40px;
      height: 40px;
      z-index: 100;

      @media ${device.tablet} {
        width: 44px;
        height: 44px;
      }
    }

    button:before {
      opacity: 0 !important;
    }

    @media ${device.tablet} {
      left: 0px;
    }
  }
  .slick-dots .slick-active {
    overflow: visible;

    .goTo {
      font-weight: bold;

      span {
        border-bottom-color: ${(props) =>
          props.theme === constants.theme.darkTheme
            ? constants.color.white
            : constants.color.black};
        border-bottom-style: solid;
        border-bottom-width: 2px;
        color: ${(props) =>
          props.theme === constants.theme.darkTheme
            ? constants.color.white
            : constants.color.black};
      }
    }
  }
`;

export const ContentContainer = styled.div`
  flex-direction: column;
  flex-grow: 1;
  height: calc(100vh - 320px);
  min-height: 320px;
  max-height: 1080px;
  outline: none;

  @media ${device.tablet} {
    height: 100vh;
    margin: 0 auto;
    max-height: 1080px;
    max-width: 1920px;
  }
  @media ${device.smallDesktop} {
    height: 100vh;
  }
  @media ${device.desktop} {
    height: 100vh;
  }
  @media ${device.largeDesktop} {
    height: 100vh;
  }
`;

export const TextBlockContent = styled.div`
  height: fit-content;
  outline: none;

  @media ${device.largeDesktop} {
    height: auto;
  }
`;

export const CustomPagination = styled.div`
  height: 40px;
  width: 100%;
  position: relative;
  z-index: 1;
`;

export const ImageContainer = styled.div`
  position: relative;
  height: 100%;
  min-height: 320px;
  background-position: center;
  top: 0px;
  background-color: ${(props) =>
    props.theme === constants.theme.darkTheme ? constants.color.black : constants.color.white};
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: contain;
  height: 100%;
  width: 100%;

  @media screen and (min-width: 320px) and (max-width: 920px) and (orientation: landscape) {
    height: 100%;
  }
  @media ${device.tablet} {
    height: 100%;
    max-height: 1080px;
  }
`;

export const SlideText = styled.div`
  padding-top: 6px;
  @media ${device.smallDesktop} {
    left: 8%;
  }
`;
