import { useI18n } from '@oneaudi/i18n-context';
import React from 'react';
import { constants } from '../../../constants';
import { messages } from '../../../i18n/messages';
import Tooltip from './Tooltip';

type PauseIconProps = {
  theme: string;
};

// eslint-disable-next-line
export const PauseIcon: React.FC<PauseIconProps> = (props) => {
  const { theme } = props;
  const currentColor =
    theme === constants.theme.darkTheme ? constants.color.white : constants.color.white;
  if (typeof window !== 'undefined' && window.innerWidth < 768) {
    return (
      <Tooltip text={useI18n(messages.pause)}>
        <svg
          width="22"
          height="22"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-describedby={useI18n(messages.pause)}
        >
          <span>{useI18n(messages.pause)}</span>
          <path d="M13.5 7V16M9.5 7V16V7Z" stroke="white" strokeLinejoin="round" />
          <path
            d="M11.5 22.5C17.5751 22.5 22.5 17.5751 22.5 11.5C22.5 5.42487 17.5751 0.5 11.5 0.5C5.42487 0.5 0.5 5.42487 0.5 11.5C0.5 17.5751 5.42487 22.5 11.5 22.5Z"
            stroke={currentColor}
            strokeMiterlimit="10"
          />
        </svg>
      </Tooltip>
    );
  }
  return (
    <Tooltip text={useI18n(messages.pause)}>
      <svg
        width="44"
        height="44"
        viewBox="0 0 48 48"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <span>{useI18n(messages.pause)}</span>
        <path
          d="M20.5,16 L20.5,31 M26.5,16 L26.5,31 M23.5,45.5 C35.6502645,45.5 45.5,35.6502645 45.5,23.5 C45.5,11.3497355 35.6502645,1.5 23.5,1.5 C11.3497355,1.5 1.5,11.3497355 1.5,23.5 C1.5,35.6502645 11.3497355,45.5 23.5,45.5 Z"
          stroke={currentColor}
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        />
      </svg>
    </Tooltip>
  );
};
