import React from 'react';
import { constants } from '../../../constants';
import Tooltip from './Tooltip';

type LeftArrowProps = {
  theme: string;
};

// eslint-disable-next-line
export const LeftArrow: React.FC<LeftArrowProps> = (props) => {
  const { theme } = props;
  const currentColor =
    theme === constants.theme.darkTheme ? constants.color.white : constants.color.black;

  return (
    <Tooltip text="back-small" arrow>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        aria-describedby="back-small"
      >
        <span>back-small</span>
        <path
          d="M14 16.9L8.5 11.5 14 6.1"
          stroke={currentColor}
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        />
      </svg>
    </Tooltip>
  );
};
