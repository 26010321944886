import { useI18n } from '@oneaudi/i18n-context';
import React from 'react';
import { constants } from '../../../constants';
import { messages } from '../../../i18n/messages';
import Tooltip from './Tooltip';

type MuteIconProps = {
  theme: string;
};

// eslint-disable-next-line
export const MuteIcon: React.FC<MuteIconProps> = (props) => {
  const { theme } = props;
  const currentColor =
    theme === constants.theme.darkTheme ? constants.color.white : constants.color.white;

  if (typeof window !== 'undefined' && window.innerWidth < 768) {
    return (
      <Tooltip text={useI18n(messages.mute)}>
        <svg
          width="22"
          height="22"
          viewBox="0 0 24 24"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          aria-describedby={useI18n(messages.mute)}
        >
          <span>{useI18n(messages.mute)}</span>
          <path
            d="M11.5,22.5 C17.5751322,22.5 22.5,17.5751322 22.5,11.5 C22.5,5.42486775 17.5751322,0.5 11.5,0.5 C5.42486775,0.5 0.5,5.42486775 0.5,11.5 C0.5,17.5751322 5.42486775,22.5 11.5,22.5 Z M5.5,9.5 L8.5,9.5 L8.5,13.5 L5.5,13.5 L5.5,9.5 Z M8.5,13.5 L11.5,15.4 L11.5,7.5 L8.5,9.5 M14.1,10 C14.6,10.8 14.9,12.2 13.2,13.9 M15.9,7.8 C16.9,8.9 19.1,12.2 15.5,15.6 M17.8,2.5 L3.7,19.3 L17.8,2.5 Z"
            stroke={currentColor}
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          />
        </svg>
      </Tooltip>
    );
  }
  return (
    <Tooltip text={useI18n(messages.mute)}>
      <svg
        width="44"
        height="44"
        viewBox="0 0 48 48"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <span>{useI18n(messages.mute)}</span>
        <path
          d="M14.5,20.5 L18.5,20.5 L18.5,26.5 L14.5,26.5 L14.5,20.5 Z M18.5,26.5 L22.5,29.4 L22.5,17.6 L18.5,20.5 M26,19.9 C27.9,21.9 27.9,25 26,27 M28.5,17.3 C28.5,17.3 35.2,23.2 28.5,29.6 M14,32.1 L27.9,14 L14,32.1 Z M23.5,45.5 C35.6502645,45.5 45.5,35.6502645 45.5,23.5 C45.5,11.3497355 35.6502645,1.5 23.5,1.5 C11.3497355,1.5 1.5,11.3497355 1.5,23.5 C1.5,35.6502645 11.3497355,45.5 23.5,45.5 Z"
          stroke={currentColor}
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        />
      </svg>
    </Tooltip>
  );
};
