import React, { ReactElement } from 'react';
import styled from 'styled-components';

const ToolTipTextContainer = styled.div<{ text?: string; arrow?: boolean }>`
  position: relative;
  display: inline-block;

  :hover {
    ::before {
      content: ${({ text }) => `'${text}'`};
      position: absolute;
      top: 100%;
      left: 70%;
      padding: 3px 5px;
      background-color: #27292b;
      color: white;
      border: 1px solid #595e62;
      white-space: nowrap;
      font-size: 11px;
      visibility: hidden;
      animation: display 1s forwards;
      animation-delay: 2s;
      left: 50%;
      transform: translateX(-50%);

      ${({ arrow }) =>
        arrow
          ? `
        padding: 9px 5px;
        top: 120%;
      `
          : ''}

      @keyframes display {
        from {
          visibility: hidden;
        }
        to {
          visibility: visible;
        }
      }
    }
  }
`;

interface TooltipProps {
  text?: string;
  arrow?: boolean;
  children?: ReactElement;
}

export const Tooltip = ({ text, arrow, children }: TooltipProps) => {
  return (
    <ToolTipTextContainer text={text} arrow={arrow}>
      {children}
    </ToolTipTextContainer>
  );
};

export default Tooltip;
