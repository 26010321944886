/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/**
 * This is the entry point for Feature Hub App integration
 */

import React from 'react';
import { FeatureAppDefinition, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import type { Logger } from '@feature-hub/logger';
import { ContentServiceV1 } from '@oneaudi/content-service';
import { RenderModeServiceV1 } from '@oneaudi/render-mode-service';
import { UniversalEditorProvider } from '@oneaudi/falcon-tools';
import I18nContextComponent from '@oneaudi/i18n-context';
import { I18NServiceV1 } from '@oneaudi/i18n-service';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service/dist/v2/types';
import { FootnoteContextProvider } from '@oneaudi/feature-app-utils';
import {
  AudiFootnoteReferenceServiceInterfaceV3,
  AudiFootnoteRefernceServiceScopeManagerInterfaceV3,
} from '@volkswagen-onehub/audi-footnote-reference-service';
import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import App from './FeatureApp';
import { ContentContextProvider } from './contexts';
import { EditorJsonContent } from './contexts/ContentInterfaces';
import { i18nScope } from '../i18n';

export interface FeatureServiceDependencies extends FeatureServices {
  readonly 's2:logger'?: Logger;
  readonly 'audi-content-service': ContentServiceV1;
  readonly 'dbad:audi-vue-formatter-service': VueFormatterServiceInterfaceV1;
  readonly 'audi-footnote-reference-service'?: AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  readonly 'locale-service': LocaleServiceV1;
  readonly 'audi-tracking-service'?: TrackingServiceV2;
  readonly 's2:serialized-state-manager'?: SerializedStateManagerV1;
  readonly 's2:async-ssr-manager'?: AsyncSsrManagerV1;
  readonly 'audi-render-mode-service'?: RenderModeServiceV1;
}

// allow setting ssr mode via URL, so it's set before anything launches
if (typeof window !== 'undefined') {
  const searchParams = new URLSearchParams(window.location.search);
  let ssr = false;
  if (searchParams.has('ssr')) {
    const ssrQueryParam = searchParams.get('ssr') as string;
    ssr = ssrQueryParam === 'true';
    // eslint-disable-next-line no-console
    console.debug(`Query parameter 'ssr' recognized: ${ssr}`);
  }

  // eslint-disable-next-line
  (window as any).__SSR__ = ssr;
}

export type SSRContent = // eslint-disable-next-line
  any | undefined;

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies> = {
  dependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      'audi:envConfigService': '^1.0.0',
      'dbad:audi-vue-formatter-service': '^1.0.0',
      'locale-service': '^1.0.0',
      'dbad:audi-i18n-service': '^1.0.0',
      'audi-footnote-reference-service': '^3.0.0',
    },
    externals: {
      react: '^16.13.0||^17.0.2||^18.2.0',
      'styled-components': '*',
    },
  },

  optionalDependencies: {
    featureServices: {
      'audi-content-service': '^1.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'audi-render-mode-service': '^1.0.0',
    },
  },
  create: ({ featureServices, featureAppId, config }) => {
    const loggerService = featureServices['s2:logger'];
    const i18nData = featureServices['dbad:audi-i18n-service'] as I18NServiceV1;
    const contentService = featureServices['audi-content-service'];
    const localeService = featureServices['locale-service'];
    const vueFormatterService = featureServices['dbad:audi-vue-formatter-service'];
    const paramsString = typeof window !== 'undefined' ? window.location.search : '';
    const searchParams = new URLSearchParams(paramsString);
    const renderModeService = featureServices['audi-render-mode-service'];

    const providerfootnoteService = featureServices[
      'audi-footnote-reference-service'
    ] as AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
    loggerService?.info('Feature App created.');

    const footnoteReferenceService: AudiFootnoteReferenceServiceInterfaceV3 =
      providerfootnoteService?.getDefaultScopeRefService();

    // SSR
    // --------------------------------------------------------------------
    const asyncSSRManager = featureServices['s2:async-ssr-manager'];
    const serializedStateManager = featureServices['s2:serialized-state-manager'];

    // on the server
    if (asyncSSRManager) {
      asyncSSRManager.scheduleRerender(
        (async () => {
          const content = await contentService?.getContent();
          serializedStateManager?.register(() => JSON.stringify(content));
        })(),
      );
    }

    return {
      render: () => {
        return (
          <UniversalEditorProvider
            contentService={contentService}
            renderModeService={renderModeService}
          >
            <FootnoteContextProvider footnoteReferenceService={footnoteReferenceService}>
              <ContentContextProvider
                localeService={localeService}
                vueFormatterService={vueFormatterService}
                // eslint-disable-next-line
                contentService={contentService!}
                footnoteReferenceService={providerfootnoteService}
                featureAppId={featureAppId}
                loggerService={loggerService}
              >
                <I18nContextComponent
                  i18nData={i18nData}
                  language={localeService.language}
                  scopes={[i18nScope]}
                >
                  <div data-fefa-custom-id={footnoteReferenceService?.getConsumerId()}>
                    <App
                      environment={searchParams.get('tracking') || 'adapter'}
                      trackingService={featureServices['audi-tracking-service']}
                      loggerService={loggerService}
                      config={config as EditorJsonContent}
                      localeService={localeService}
                      featureAppId={featureAppId}
                    />
                  </div>
                </I18nContextComponent>
              </ContentContextProvider>
            </FootnoteContextProvider>
          </UniversalEditorProvider>
        );
      },
    };
  },
};
export default featureAppDefinition;
