/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  createVinByRegExpRemover,
  TrackingServiceV3,
  createAndBootstrapTrackingService as createLibraryTrackingService,
  TrackingServiceV3BootstrapOptions,
} from '@volkswagen-onehub/oneaudi-os-onesight';
import {
  ComponentAdditionsV2,
  TrackingServiceV2,
  defineTrackingService,
  DataLayerV2,
  UrlSanitizerV2,
} from '@oneaudi/audi-tracking-service';
import {
  createTrackingService as createAdapterTrackingService,
  FeatureServicesBindingAdditions,
} from '@volkswagen-onehub/oneaudi-os-adapter-tracking';
import { Logger } from '@feature-hub/core';
import { generateUUID } from '../../helper/generateUUID';
import { TrackingV2Helper } from './TrackingV2Helper';
import { TrackingV3Helper } from './TrackingV3Helper';

export type TrackingServiceEnvironment = 'adapter' | 'library' | 'tfs';

const defaultComponentInfo = {
  attributes: {
    implementer: 39,
    absoluteNumber: 1,
    instanceNumber: 1,
    version: '8.2.0',
    mediaType: 'image',
  },
};

export class TrackingHelperWrapper {
  // eslint-disable-next-line
  // @ts-ignore
  private trackingServiceHelper: TrackingV2Helper | TrackingV3Helper;

  private loggerService: Logger;

  private trackingServiceSource: TrackingServiceEnvironment;

  private id: string;

  getTrackingServiceHelper() {
    return this.trackingServiceHelper;
  }

  getTrackingServiceSource() {
    return this.trackingServiceSource;
  }

  getTrackingService(): (TrackingServiceV2 & FeatureServicesBindingAdditions) | TrackingServiceV3 {
    return this.trackingServiceHelper.getTrackingService();
  }

  defineV2Service(
    defineFn: (
      appId: string,
      datalayer?: DataLayerV2,
      urlSanitizer?: UrlSanitizerV2,
    ) => TrackingServiceV2 & FeatureServicesBindingAdditions,
    initialTrackingService: TrackingServiceV2,
  ) {
    const trackingService = initialTrackingService ?? defineFn(this.id);
    this.trackingServiceHelper = new TrackingV2Helper(
      trackingService as TrackingServiceV2 & FeatureServicesBindingAdditions,
      this.loggerService,
    );

    this.getTrackingService().featureAppName = __FEATURE_APP_NAME__;
    (this.getTrackingService() as TrackingServiceV2).updateComponent(
      defaultComponentInfo as ComponentAdditionsV2,
    );
  }

  defineV3Service(
    defineFn: (options: TrackingServiceV3BootstrapOptions) => TrackingServiceV3,
    initialTrackingService?: TrackingServiceV3,
  ) {
    const trackingService =
      initialTrackingService ??
      defineFn({
        appId: this.id,
        appName: __FEATURE_APP_NAME__,
        appVersion: defaultComponentInfo.attributes.version,
        implementer: defaultComponentInfo.attributes.implementer,
      });
    this.trackingServiceHelper = new TrackingV3Helper(trackingService, this.loggerService);
  }

  constructor(
    loggerService: Logger,
    trackingServiceSource: TrackingServiceEnvironment,
    featureAppId: string,
    trackingService?: TrackingServiceV2 | TrackingServiceV3,
  ) {
    this.loggerService = loggerService;
    this.trackingServiceSource = trackingServiceSource;
    this.id = featureAppId;

    switch (trackingServiceSource) {
      case 'adapter':
        loggerService.info(`Creating Tracking Service from TRACKING ADAPTER for App ${this.id}.`);
        // @ts-expect-error DataLayerV2 type difference
        this.defineV2Service(createAdapterTrackingService, trackingService as TrackingServiceV2);
        break;
      case 'tfs':
        loggerService.info(
          `Creating Tracking Service from TRACKING FEATURE SERVICE for App ${this.id}.`,
        );
        // eslint-disable-next-line
        const digitalData = () => {
          // eslint-disable-next-line
          if (typeof window !== 'undefined') {
            // eslint-disable-next-line
            return window.digitalData;
          }
        };
        // eslint-disable-next-line
        const serviceProviderDefinition = defineTrackingService({
          // eslint-disable-next-line
          datalayer: digitalData(),
          sanitizeUrl: createVinByRegExpRemover(loggerService),
        });

        this.defineV2Service(
          // eslint-disable-next-line
          // @ts-ignore
          null,
          (trackingService as TrackingServiceV2) ??
            // @ts-expect-error empty object
            (serviceProviderDefinition
              .create({
                featureServices: { 's2:logger': loggerService },
              })
              ?.['2.1.0'](this.id).featureService as TrackingServiceV2),
        );
        break;
      case 'library':
        loggerService.info(`Creating Tracking Service from TRACKING LIBRARY for App ${this.id}.`);
        this.defineV3Service(createLibraryTrackingService, trackingService as TrackingServiceV3);
        break;
      default:
        loggerService.warn(
          `No known Tracking Source was provided: ${trackingServiceSource}. TRACKING ADAPTER will be used for ${this.id}`,
        );
        // @ts-expect-error DataLayerV2 type difference
        this.defineV2Service(createAdapterTrackingService, trackingService as TrackingServiceV2);
    }
  }
}

export function getNextId(initialId: string): string {
  return `${initialId}-${generateUUID()}`;
}
