import styled from 'styled-components';
import { constants } from '../../../constants';

const device = {
  desktop: `(min-width:${constants.displaySizes.desktop})`,
  largeDesktop: `(min-width:${constants.displaySizes.largeDesktop})`,
  smallDesktop: `(min-width:${constants.displaySizes.smallDesktop})`,
  mobile: `(min-width:${constants.displaySizes.mobile})`,
  tablet: `(min-width:${constants.displaySizes.tablet})`,
};

export const CloseButtonContainer = styled.div`
  cursor: pointer;
  margin: 30px;
  position: absolute;
  right: 0;
  z-index: 2;

  @media (max-width: '767px') {
    display: none;
  }
`;

export const PosterImage = styled.img`
  bottom: 0;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`;

export const PlayButton = styled.button`
  background-repeat: no-repeat;
  background-size: contain;
  background: transparent;
  border: none;
  cursor: pointer;
  display: block;
  overflow: visible;
  padding: 0px;
  position: absolute;
  z-index: 3;
  height: 48px;
  width: 48px;
  bottom: 0px;
  left: 28px;

  :focus {
    outline: none;
  }

  :focus-visible {
    outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color;
  }

  @media ${device.mobile} {
    height: 48px;
    width: 48px;
    bottom: 0px;
    left: 28px;
  }

  @media ${device.tablet} {
    height: 48px;
    width: 48px;
    bottom: 23.5px;
    left: 40px;
  }

  @media ${device.smallDesktop} {
    bottom: 24.25px;
    left: 60px;
  }

  @media ${device.desktop} {
    bottom: 37.97px;
    left: 96px;
  }

  @media ${device.largeDesktop} {
    bottom: 42px;
    left: 336px;
  }
`;

export const MuteButton = styled.button`
  background-repeat: no-repeat;
  background-size: contain;
  background: transparent;
  border: none;
  cursor: pointer;
  display: block;
  overflow: visible;
  padding: 0px;
  position: absolute;
  z-index: 3;
  height: 48px;
  width: 48px;
  bottom: 0px;
  left: 76px;

  :focus {
    outline: none;
  }

  :focus-visible {
    outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color;
  }

  @media ${device.mobile} {
    height: 48px;
    width: 48px;
    bottom: 0px;
    left: 76px;
  }

  @media ${device.tablet} {
    height: 48px;
    width: 48px;
    bottom: 23.5px;
    left: 111px;
  }

  @media ${device.smallDesktop} {
    bottom: 24.25px;
    left: 131px;
  }
  @media ${device.desktop} {
    bottom: 37.97px;
    left: 166.94px;
  }

  @media ${device.largeDesktop} {
    bottom: 42px;
    left: 408px;
  }
`;
export const ClosedCaptionButton = styled.button`
  background-repeat: no-repeat;
  background-size: contain;
  background: transparent;
  border: none;
  cursor: pointer;
  display: block;
  overflow: visible;
  padding: 0px;
  position: absolute;
  z-index: 3;
  height: 48px;
  width: 48px;
  bottom: 0px;
  right: 20px;

  :focus {
    outline: none;
  }

  :focus-visible {
    outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color;
  }

  @media ${device.mobile} {
    height: 48px;
    width: 48px;
    bottom: 0px;
    right: 16px;
  }

  @media ${device.tablet} {
    bottom: 23.5px;
    right: 77px;
    svg {
      height: 24px;
      width: 24px;
    }
  }

  @media ${device.smallDesktop} {
    bottom: 24.25px;
    right: 98px;
  }
  @media ${device.desktop} {
    bottom: 37.97px;
    right: 104px;
  }

  @media ${device.largeDesktop} {
    bottom: 42px;
    right: 337px;
  }
`;

export const Video = styled.video`
  position: absolute;
  background-color: black;
  border-bottom: 1px solid transparent;
  overflow: hidden;
  z-index: 1;
  object-fit: contain;
  height: 100%;
  width: 100%;

  ::cue {
    font-size: 1.2em;
  }

  @media screen and (min-width: 320px) and (max-width: 920px) and (orientation: landscape) {
    height: 100%;
  }

  @media ${device.tablet} {
    ::cue {
      font-size: x-large;
    }
  }
`;

export const VideoContainer = styled.div`
  align-items: center;
  background-color: black;
  background-position: center;
  width: 100%;
  min-height: 320px;
  display: flex;
  height: calc(100vh - 320px);
  max-height: 1080px;

  top: 0px;
  justify-content: center;
  position: relative;

  @media ${device.tablet} {
    height: 100vh;
  }
  @media ${device.smallDesktop} {
    height: 100vh;
  }
  @media ${device.desktop} {
    height: 100vh;
  }
  @media ${device.largeDesktop} {
    height: 100vh;
  }
`;
